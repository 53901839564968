import { useSettings } from "../SettingsContext";

const Api = () => {
  const { getCredentials, makeCredentials } = useSettings();
  const credentials = getCredentials();

  const login = async (username, password) => {
    const headers = {
      "Content-Type": "application/json",
    };

    headers["Authorization"] = `Basic ${makeCredentials(username, password)}`;

    try {
      const response = await fetch(`/api/home/login`, {
        method: "GET",
        headers,
      });
      console.log(credentials);
      if (!response.ok) {
        console.log(response.json());
        return { logged_in: false };
      }
      return { logged_in: true };
    } catch (error) {
      console.error("Fetch error: ", error.message);
      throw error;
    }
  };

  const post = async (endpoint, payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    if (credentials) {
      headers["Authorization"] = `Basic ${credentials}`;
    }

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      });
      return response.json();
    } catch (error) {
      console.error("Fetch error: ", error.message);
      throw error;
    }
  };

  const get = async (endpoint) => {
    const headers = {
      "Content-Type": "application/json",
    };

    if (credentials) {
      headers["Authorization"] = `Basic ${credentials}`;
    }

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers,
      });
      return response.json();
    } catch (error) {
      console.error("Fetch error: ", error.message);
      throw error;
    }
  };

  return {
    login,
    post,
    get,
  };
};

export default Api;
