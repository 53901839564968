import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Group, Button, ScrollArea, Tooltip } from "@mantine/core";
import { IconHeart, IconThumbUp, IconThumbDown } from "@tabler/icons-react";
import { useSettings } from "../../SettingsContext";
import SubmitModal from "../../components/SubmitModal/SubmitModal";
import Api from "../../api/Api";
import classes from "./Ranker.module.css";

export function Ranker() {
  const { get } = Api();
  const [currentId, setCurrentId] = useState(0);
  const [ids, setIds] = useState([]);
  const [qaData, setQaData] = useState([]);
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [dataIndex, setDataIndex] = useState(0);
  const [db, setDb] = useState(null);
  const transitionClass = isVisible ? classes.fadeIn : classes.fadeOut;
  const openModal = useRef(null);
  const navigate = useNavigate();
  const { logout } = useSettings();

  const updateRanking = (rank) => {
    const updatedData = qaData.map((item) => {
      if (item.id === ids[currentId]) {
        return { ...item, ranking: rank };
      }
      return item;
    });

    const existingItem = updatedData.find((item) => item.id === ids[currentId]);

    if (existingItem) {
      setQaData(updatedData);
    } else {
      // If the id doesn't exist, create a new object with the id and ranking
      const newItem = {
        id: ids[currentId],
        ranking: rank,
        competency: db[currentId].competency,
      };
      setQaData([...qaData, newItem]);
    }

    const newId = Math.min(currentId + 1, ids.length - 1);
    if (newId !== currentId) {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentId(newId);
        setIsVisible(true);
      }, 500);
    }
  };

  const rankButtons = [
    {
      label: "love",
      icon: <IconHeart size={36} />,
    },
    {
      label: "pass",
      icon: <IconThumbUp size={36} />,
    },
    {
      label: "fail",
      icon: <IconThumbDown size={36} />,
    },
  ];

  const getPreviewData = (data) => {
    const preview = data.map((item, index) => {
      let iconElement = null;

      if (item.ranking === "fail") {
        iconElement = <IconThumbDown size={24} />;
      } else if (item.ranking === "pass") {
        iconElement = <IconThumbUp size={24} />;
      } else if (item.ranking === "love") {
        iconElement = <IconHeart size={24} />;
      }

      return {
        key: index + 1,
        value: iconElement,
        id: item.id,
        competency: item.competency,
        ranking: item.ranking,
      };
    });
    return preview;
  };
  const payload = getPreviewData(qaData);

  const rankItems = rankButtons.map((button) => {
    const handleClick = (event) => {
      event.preventDefault();
      if (isVisible) {
        updateRanking(button.label);
      }
    };

    const currentRanking = qaData.find(
      (item) => item.id === ids[currentId]
    )?.ranking;
    const isCurrentRanking = currentRanking === button.label;

    return (
      <Button
        key={button.label}
        size="compact-lg"
        autoContrast
        className={`${classes.button} ${classes.buttonSpacing}`}
        styles={{
          root: { backgroundColor: isCurrentRanking ? "pink" : "white" },
          label: { color: "black" },
        }}
        onClick={handleClick}
      >
        {button.icon}
      </Button>
    );
  });

  const handleSubmit = () => {
    setDataIndex((prevIndex) => prevIndex + 1);
    setCurrentId(0);
    setQaData([]);
    console.log("Submission successful");
  };

  const goBack = () => {
    const newId = Math.max(currentId - 1, 0);
    setCurrentId(newId);
  };

  const getIds = useCallback(
    (db) => {
      const dataIds = db.map((item) => {
        return item.id;
      });
      setIds(dataIds);
    },
    [setIds]
  );

  const fetchData = useCallback(async () => {
    try {
      const endpoint = "/api/sessions/competency";
      const tmpData = await get(endpoint);

      if (tmpData.length === 0) {
        // If no data is returned, navigate to another page
        navigate("/no-data");
      } else {
        setData(tmpData);
        setDataIndex(0);
        setDb(tmpData[0]);
        getIds(tmpData[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [get, setData, setDataIndex, setDb, getIds]);

  useEffect(() => {
    if (dataIndex >= data.length) {
      fetchData();
    } else if (dataIndex > 0) {
      setDb(data[dataIndex]);
      getIds(data[dataIndex]);
    }
  }, [dataIndex, data, fetchData, getIds]);

  useEffect(() => {
    const fetchDataAndRedirect = async () => {
      try {
        const endpoint = "/api/sessions/competency";
        const tmpData = await get(endpoint);

        if (tmpData.length === 0) {
          // If no data is returned, navigate to another page
          navigate("/no-data");
        } else {
          setData(tmpData);
          setDataIndex(0);
          setDb(tmpData[0]);
          getIds(tmpData[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndRedirect();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <SubmitModal
        payload={payload}
        apiEndpoint={"/api/ranking/submit/competency"}
        openModal={openModal}
        onSubmit={handleSubmit}
      />
      <ScrollArea className={classes.scrollArea}>
        <div className={transitionClass}>
          {db ? (
            <>
              <div className={classes.question}>
                <strong>Q:</strong> {db[currentId].question}
              </div>
              <div className={classes.answer}>
                <strong>A:</strong> {db[currentId].answer}
              </div>{" "}
            </>
          ) : null}
        </div>
      </ScrollArea>
      <Container className={classes.buttonContainer}>
        <Container className={classes.buttonGroup}>
          <Group spacing="xl" gap={1} className={classes.buttons}>
            {rankItems}
          </Group>
        </Container>
        <Container className={classes.buttonGroup}>
          <Group gap={3} className={classes.buttons}>
            <Button
              key="back"
              size="compact-md"
              autoContrast
              className={classes.button}
              styles={{
                root: { backgroundColor: "white" },
                label: { color: "black" },
              }}
              onClick={() => goBack()}
              disabled={currentId === 0}
            >
              Back
            </Button>
            {dataIndex === 0 ? (
              <Button
                key="logout"
                size="compact-md"
                autoContrast
                className={classes.button}
                styles={{
                  root: { backgroundColor: "white" },
                  label: { color: "black" },
                }}
                onClick={logout}
              >
                Logout
              </Button>
            ) : (
              <Tooltip
                label="It is recommended to finish labeling all the data in this batch before logging out."
                position="top"
                withArrow
              >
                <div className={classes.counter}>
                  Sections left: {data.length - dataIndex }
                </div>
              </Tooltip>
            )}
            <Button
              key="submit"
              size="compact-md"
              autoContrast
              className={classes.button}
              styles={{
                root: { backgroundColor: "white" },
                label: { color: "black" },
              }}
              onClick={() => openModal.current()}
              disabled={qaData.length !== ids.length}
            >
              Submit
            </Button>
          </Group>
        </Container>
      </Container>
    </Container>
  );
}
