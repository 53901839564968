import React from "react";
import classes from "./NoDataPage.module.css";
import { Button } from "@mantine/core";
import { useSettings } from "../../SettingsContext";

export function NoDataPage() {
  const { logout } = useSettings();
  return (
    <div>
      <h2>No Data Available</h2>
      <p>Sorry, there is no data available at the moment.</p>
      <p>Check back again soon!</p>
      <Button
        key="logout"
        size="compact-md"
        autoContrast
        className={classes.button}
        styles={{
          root: { backgroundColor: "white" },
          label: { color: "black" },
        }}
        onClick={logout}
      >
        Logout
      </Button>
    </div>
  );
}
