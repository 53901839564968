import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { Ranker } from './pages/Ranker';
import { AuthenticationForm } from './pages/AuthenticationForm/index.jsx';
import { NoDataPage } from './pages/NoDataPage';
import { useSettings } from './SettingsContext';

function App() {
  const { isLoggedIn } = useSettings();

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn() ? (
                <Navigate to="/ranker" replace />
              ) : (
                <AuthenticationForm />
              )
            }
          />
          <Route
            path="/ranker"
            element={
              isLoggedIn() ? (
                <Ranker />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/no-data"
            element={
              isLoggedIn() ? (
                <NoDataPage />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;