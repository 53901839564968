import React, { createContext, useState, useContext, useEffect } from "react";

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

// Helper function to load settings or default values
const loadInitialSettings = () => {
  const savedSettings = localStorage.getItem("settings");
  return savedSettings
    ? JSON.parse(savedSettings)
    : {
        username: null,
        password: null,
      };
};

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(loadInitialSettings);


  function makeCredentials(username, password) {
    if (!username || !password) {
      return null;
    } else if (!username.trim() || !password.trim()) {
      return null;
    }
    return btoa(username + ":" + password);
  }

  function getCredentials() {
    return makeCredentials(settings.username, settings.password);
  }

  const login = async (username, password) => {
    const updatedSettings = {
      ...settings,
      username: username,
      password: password,
    };
    setSettings(updatedSettings);
  };

  const logout = () => {
    const updatedSettings = {
      ...settings,
      username: null,
      password: null,
    };
    setSettings(updatedSettings);
  };

  const isLoggedIn = () => {
    return !!(
      settings.username &&
      settings.password &&
      settings.username.trim() &&
      settings.password.trim()
    );
  };

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        makeCredentials,
        getCredentials,
        login,
        logout,
        isLoggedIn,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
