import React from "react";
import { upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Paper,
  Button,
  Stack,
  Container,
} from "@mantine/core";
import { useSettings } from "../../SettingsContext";
import Api from "../../api/Api";

export function AuthenticationForm() {
  const { login, logout } = useSettings();
  const api = Api();

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const handleSubmit = async () => {
    try {      
      const response = await api.login(form.values.email, form.values.password);
      if (response.logged_in) {
        login(form.values.email, form.values.password);
       } else {
        logout();
        console.error("Login failed:", response.error);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <Container>
      <h1>Upwage Candidate Ranker</h1>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              required
              label="Email"
              placeholder="user@email.com"
              value={form.values.email}
              onChange={(event) =>
                form.setFieldValue("email", event.currentTarget.value)
              }
              error={form.errors.email && "Invalid email"}
              radius="md"
            />

            <PasswordInput
              required
              label="Password"
              placeholder="Your password"
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={
                form.errors.password &&
                "Password should include at least 6 characters"
              }
              radius="md"
            />
            <Button type="submit" radius="xl">
              {upperFirst("login")}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
