import React, { useState } from "react";
import { Modal, Button } from "@mantine/core";
import Api from "../../api/Api"

const SubmitModal = ({ payload, apiEndpoint, openModal, onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { post } = Api();

  // Allow the parent component to control the modal state
  openModal.current = () => setIsModalOpen(true);

  const handleConfirm = async () => {
    console.log('API Endpoint:', apiEndpoint);

    // strip payload
    const cleanPayload = payload.map(({ key, value, ...rest }) => rest);
    console.log('Payload:', cleanPayload);

    try {
        const response = await post(apiEndpoint, cleanPayload);
        console.log('Response:', response);
        setIsModalOpen(false);
    } catch (error) {
        console.error('Error in handleConfirm:', error);
    }
    onSubmit();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // Close the modal without confirming
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        opened={isModalOpen}
        onClose={handleCancel}
        title="Confirm Submission"
      >
        <div>
          <h3>Rankings:</h3>
          {payload.map((item, index) => (
            <p key={index}>
              {item.key}: {item.value}
            </p>
          ))}
        </div>
        <p>Submit your rankings?</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button color="red" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="green" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SubmitModal;
